<template>
  <category-risk
    category="policies"
    title="Policies & Procedures Security"
    :banner="banner"
  />
</template>

<script>
  export default {
    name: 'DashboardPoliciesProcesures',

    data: () => ({
      banner: {
        title: 'What is Policies & Procesures Security?',
        body: "An IT Security Policy identifies the rules and procedures for all individuals accessing and using an organization's IT assets and resources. An Information Technology (IT) Security Policy identifies the rules and procedures for all individuals accessing and using an organization's IT assets and resources."
      }
    }),

    components: {
      CategoryRisk: () => import('../component/Category'),
    }
  }
</script>
